import Container from 'src/components/views/Container/Container';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { styles } from './ErrorHeader.styles';
import { getContacts } from './getContacts';

export default function ErrorHeader() {
  const { chain, country, imgRoot } = useAppConfig();

  const { phone, email } = getContacts(country);

  return (
    <header className='ErrorHeader' data-marker='error_header' >
      <Container>
        <div className='ErrorHeader__content'>
          <a className='ErrorHeader__retailerLink' href='/'>
            <figure>
              <img
                className='ErrorHeader__retailerLogo'
                src={`${imgRoot}/store_logos/${chain}.svg`}
                alt='Logo'
                data-marker='retailer_logo'
              />
            </figure>
          </a>

          <div className='ErrorHeader__contacts'>
            <a href={`mailto:${email}`} className='ErrorHeader__contactsItem'>
              {email}
            </a>
            <a href={`tel:${phone}`} className='ErrorHeader__contactsItem'>
              {phone}
            </a>
          </div>
        </div>
      </Container>
      <style jsx>{styles}</style>
    </header>
  );
}
