import css from 'styled-jsx/css';
import { phone, phone_tablet, tablet } from '../../styles/media';

export const styles = css`
  .ErrorLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: #fff;
  }
  .ErrorLayout__image {
    max-width: 700px;
  }
  .ErrorLayout__image {
    margin-right: 35px;
  }
  .ErrorLayout__messageBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 448px;
    width: 85%;
    margin-left: 35px;
  }
  .ErrorLayout__bigHeader {
    font-size: 36px;
  }
  .ErrorLayout__message {
    font-size: 14px;
  }
  .ErrorLayout__smallHeader {
    color: #b4b4b4;
  }
  @media ${phone_tablet} {
    .ErrorLayout {
      flex-direction: column;
      margin: 0 auto;
    }
    .ErrorLayout__image {
      max-width: 170px;
      margin-right: 0;
      margin-bottom: 20px;
    }
    .ErrorLayout__messageBox {
      align-items: center;
      justify-content: center;
      margin: 0;
    }
    .ErrorLayout__message {
      text-align: center;
    }
  }
  @media ${tablet} {
    .ErrorLayout__bigHeader {
      font-size: 29px;
      text-align: center;
    }
  }
  @media ${phone} {
    .ErrorLayout__bigHeader {
      font-size: 22px;
      text-align: center;
    }
  }`;
