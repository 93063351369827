import NotFound from 'src/components/views/Errors/404';
import ErrorPageLayout from 'src/layouts/ErrorPageLayout/ErrorPageLayout';

export default function NotFoundErrorPage() {
  return (
    <ErrorPageLayout>
      <NotFound fullHeight={false} />
    </ErrorPageLayout>
  );
}
