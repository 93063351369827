export interface Contacts {
  phone: string;
  email: string;
}

const contactsMap: { [key: string]: Contacts } = {
  ua: { phone: '0800 20 20 20', email: 'help@zakaz.ua' },
  md: { phone: '022 010 020', email: 'help@zakaz.md' },
};

export function getContacts(country: string): Contacts {
  return contactsMap[country];
}
