import { useEffect } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import { sendNotFoundPage } from 'src/utils/marketing/enhancedEcommerce';
import ErrorLayout from '../../../layouts/ErrorLayout/ErrorLayout';

interface NotFoundProps {
  /** @description Sets the height of root element as 100vh. True by default.
   * When NotFound is used in GeneralPageLayout or other wrapper this value should be false.*/
  fullHeight?: boolean;
}

const NotFound = ({ fullHeight = true }: NotFoundProps) => {
  const localize = useLocalization();
  const bigHeader = localize('error.page.not-found.big-header');
  const buttonMessage = localize('general.go-home');

  useEffect(() => {
    sendNotFoundPage();
  }, []);

  return (
    <div className='NotFound'>
      <ErrorLayout
        imageSrc='/i/donut.png'
        imageAlt='Donut'
        smallHeader='404'
        bigHeader={bigHeader}
        buttonMessage={buttonMessage}
        buttonLink='/'
      />

      <style jsx>{`
        .NotFound {
          height: ${fullHeight? '100vh' : 'auto'};
          margin: 0 auto;
          background-color: #fff;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default NotFound;
