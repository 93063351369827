import css from 'styled-jsx/css';

export const styles = css`
    .ErrorPageLayout {
      background-color: #fff;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ErrorPageLayout__content {
      margin-top: 70px;
    }
  `;
