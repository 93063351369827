import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, Fragment, MouseEventHandler, ReactNode } from 'react';
import checkIconClassName from 'src/utils/system/checkIconClassName';
import getDataStatus from 'src/utils/system/getDataStatus';
import { styles } from './Button.styles';

export type ButtonSize = 'large' | 'small';
export type ButtonVariant = 'primary' | 'secondary' | 'ghost' | 'ghostWarn';

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  nextLinkProps?: LinkProps;
  nativeLinkProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
  dataMarkerValue?: string;
  dataTestId?: string;
  title?: string;
  children?: ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  regularText?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>,
  /** you need to use icons classes from the font file "icons.css" */
  startIcon?: string;
  endIcon?: string;
  tabIndex?: number;
  textAlignLeft?: boolean;
}

const Button = (props: ButtonProps) => {
  const {
    children,
    dataMarkerValue,
    dataTestId,
    nativeLinkProps,
    nextLinkProps,
    title,
    variant = 'primary',
    size = 'large',
    disabled,
    fullWidth,
    fullHeight,
    regularText,
    startIcon,
    endIcon,
    tabIndex,
    type = 'button',
    onClick,
    textAlignLeft,
  } = props;

  const buttonClass = classNames(
    `Button Button_${variant} Button_${size}`,
    {
      'Button_disabled': disabled,
      'Button_fullWidth': fullWidth,
      'Button_fullHeight': fullHeight,
      'Button_onlyIcon': !children,
      'Button_regularText': regularText,
      'Button_textAlignedLeft': textAlignLeft,
    },
  );

  const baseProps = {
    'data-marker': dataMarkerValue,
    'data-testid': dataTestId,
    'data-status': getDataStatus(!disabled),
    tabIndex: tabIndex,
    className: buttonClass,
    title,
    onClick,
  };

  const inner = (
    <Fragment>
      {
        checkIconClassName(startIcon) && (
          <span className={`Button__icon Button_start ${startIcon}`} />
        )
      }

      {children && (
        <span className="Button__text">
          {children}
        </span>
      )}

      {
        checkIconClassName(endIcon) && (
          <span className={`Button__icon Button_end ${endIcon}`} />
        )
      }
      <style jsx>{styles}</style>
    </Fragment>
  );

  if (nextLinkProps) {
    const { className, ...restBaseProps } = baseProps;

    return (
      <Link
        {...nextLinkProps}
        {...restBaseProps}
      >
        <span className={className}>
          {inner}
        </span>

        <style jsx>{styles}</style>
      </Link>
    );
  }

  if (nativeLinkProps) {
    return (
      <Fragment>
        <a
          {...nativeLinkProps}
          {...baseProps}
        >
          {inner}
        </a>
        <style jsx>{styles}</style>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <button
        {...baseProps}
        disabled={disabled}
        type={type}
      >
        {inner}
      </button>
      <style jsx>{styles}</style>
    </Fragment>

  );
};

export default Button;
