import Button from 'src/components/ui-kit/Button/Button';
import { styles } from './ErrorLayout.styles';

interface Props {
  imageSrc: string;
  imageAlt: string;
  smallHeader?: string;
  bigHeader: string;
  message?: string;
  buttonMessage?: string;
  buttonLink?: string;
}

function ErrorLayout(props: Props) {
  const {
    imageSrc,
    imageAlt,
    smallHeader,
    bigHeader,
    message,
    buttonMessage,
    buttonLink,
  } = props;

  const buttonIsVisible = buttonLink && buttonMessage;

  return (
    <div className='ErrorLayout'>
      <img
        className='ErrorLayout__image'
        src={imageSrc}
        alt={imageAlt}
      />

      <div className='ErrorLayout__messageBox'>
        {smallHeader &&
          (
            <p className='ErrorLayout__smallHeader'>
              {smallHeader}
            </p>
          )
        }

        <h1 className='ErrorLayout__bigHeader'>
          {bigHeader}
        </h1>

        {message && (
          <p className='ErrorLayout__message'>
            {message}
          </p>
        )}

        {
          buttonIsVisible &&
          <Button
            dataMarkerValue='Back to main'
            nativeLinkProps={{
              href: buttonLink,
            }}
          >
            {buttonMessage}
          </Button>
        }

      </div>

      <style jsx>{styles}</style>
    </div>
  );
}

export default ErrorLayout;
