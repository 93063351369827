import { tablet_desktop } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
    .ErrorHeader {
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-bottom: 3px solid var(--colors-mainBrand);
    }

    .ErrorHeader__content {
      display: flex;
      align-items: center;
      height: 47px;
    }

    .ErrorHeader figure {
      margin: 0;
      padding: 0;
    }

    .ErrorHeader__retailerLogo {
      height: 21px;
    }

    .ErrorHeader__retailerLink {
      line-height: 0;
    }

    .ErrorHeader__contacts {
      margin-left: auto;
      margin-right: 0;
      text-align: right;
    }

    .ErrorHeader__contactsItem {
      ${typography.h6}
      margin-left: 20px;
      display: inline-block;
    }

    @media ${tablet_desktop} {
      .ErrorHeader__contactsItem {
        ${typography.h5}
      }

      .ErrorHeader__retailerLogo {
        height: 36px;
      }
    }
  `;
