import { ReactNode } from 'react';
import ErrorHeader from 'src/components/containers/ErrorHeader/ErrorHeader';
import { styles } from './ErrorPageLayout.styles';

interface Props {
  children: ReactNode;
}

export default function ErrorPageLayout(props: Props) {
  const { children } = props;


  return (
    <div className='ErrorPageLayout'>
      <ErrorHeader />

      <div className='ErrorPageLayout__content'>
        {children}
      </div>

      <style jsx>{styles}</style>
    </div>
  );
}
