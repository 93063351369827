import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    cursor: pointer;
    user-select: none;
    transition: 0.15s background ease-in-out, 0.15s color ease-in-out;
    ${typography.btn}
    border-radius: var(--border-radius-roundBox);
    padding: 8px 32px;
  }

  .Button:focus {
    outline: none;
  }

  .Button_small {
    font-size: 12px;
    line-height: 16px;
  }

  .Button_large {
    font-size: 14px;
    line-height: 24px;
  }

  .Button_primary {
    color: var(--uiColors-Button-text);
    background: var(--uiColors-Button-background);
  }
  .Button_primary:hover {
    background: var(--uiColors-Button-hoverBackground);
  }
  .Button_primary:active {
    background: var(--uiColors-Button-activeBackground);
  }
  .Button_primary[disabled] {
    background: var(--uiColors-Button-disabledBackground);
    color: var(--uiColors-Button-defaultDisabledText);
  }

  .Button_disabled,
  .Button[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    color: var(--colors-doveGray)
  }

  .Button_secondary {
    background-color: var(--uiColors-Button-borderedDefaultBackground);
    color: var(--uiColors-Button-borderedDefaultText);
    border: 1px solid var(--uiColors-Button-borderedDefaultBorder);
  }
  .Button_secondary:hover {
    color: var(--uiColors-Button-text);
    background: var(--uiColors-Button-background);
  }
  .Button_secondary:active {
    background-color: var(--uiColors-Button-borderedDefaultActiveBackground);
    color: var(--uiColors-Button-text);
  }
  .Button_secondary[disabled] {
    background-color: var(--colors-white);
    border-color: var(--colors-brandLightGrey2);
    color: var(--colors-doveGray);
  }

  .Button_ghost {
    padding: 0;
    background: transparent;
    color: var(--uiColors-Button-accentedText);
  }
  .Button_ghost:hover {
    color: var(--uiColors-Button-accentedHoverText);
  }
  .Button_ghost:active {
    color: var(--uiColors-Button-activeBackground);
  }

  .Button_ghostWarn {
    padding: 0;
    background: transparent;
    color: var(--colors-red);
  }

  .Button_halfOpacity {
    opacity: 0.5
  }

  .Button__icon + .Button__text {
    margin-left: 8px;
  }

  .Button__text + .Button__icon {
    margin-left: 8px;
  }

  .Button_fullWidth {
    width: 100%;
  }

  .Button_fullHeight {
    height: 100%;
  }

  .Button_regularText {
    font-weight: 400;
  }

  .Button_large.Button_onlyIcon {
    padding: 14px 20px;
  }

  .Button_small.Button_onlyIcon {
    padding: 10px 20px;
  }

  .Button_textAlignedLeft {
    text-align: left;
  }
`;
